/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react"
import {graphql, Link} from 'gatsby';
import Layout from "../components/full-page/layout";
import SEO from "../components/seo";
import CoverImage from '../images/podcast/cover.jpeg';
import CCLogoWhite from "../images/Claritycoat AF new.svg";
import {ColorThemeContextProvider} from "../contexts/colorThemeContext";
import FullPageCta from "../components/full-page/full-page-cta";

const Podcast = ({ data, pageContext: { lang } }) => {
    if (!data) {
        return null;
    }

    const { allPrismicPodcastEpisode, prismicPodcast } = data;

    const slice = {
        primary: {
            heading: {
                text: 'The Auto Body Podcast',
            },
            subheading: {
                html: 'A podcast interviewing people in the auto body industry.',
            },
            image: {
                url: prismicPodcast.data.header_image.url,
            },
            image_mobile: {
                url: prismicPodcast.data.header_image.url,
            },
            video_url: {
                url: null,
            },
            video_mobile_url: {
                url: null,
            },
            video_autoplay: false,
            video_muted: false,
            video_loop: false,
            video_playsinline: false,
            video_controls: false,
            theme: 'dark',
            // cta_1_title: 'Scoll for more',
            // cta_1_link: {
            //     url: null,
            // }
        }
    };

    return (
      <>
          <SEO title="The Auto Body Podcast" />
          <ColorThemeContextProvider>
              <Layout
                disappearHeader={true}
                initColorTheme={'dark'}
                logo={ CCLogoWhite }
                topNav={data.prismicHome?.data.menu_links}
                footerNav={data.prismicHome?.data.footer_links}
                currentLanguage={lang}
                blogListing={data.allPrismicBlogPost?.nodes}
              >
                  <FullPageCta slice={slice} />
                  <section className="sub-content-block sub-content-block--1to3">
                      <div className="sub-content-block__item sub-content-block__item--image podcast-cover" style={{"backgroundImage": `url('${CoverImage}')`}} />
                      <div className="sub-content-block__item sub-content-block__item--text">
                          <div>
                              <p>Listen to this podcast on</p>

                              <div className={"podcast-providers"}>
                                  <a href={"https://podcasts.apple.com/podcast/id1598731084"} target="_blank" title={"Listen on Apple Podcast"} className={"podcast apple-podcast-url"}>Listen on Apple Podcast</a>
                                  <a href={"https://open.spotify.com/show/1NVWPYjmdTJ5yBVNvrcArB"} target="_blank" title={"Listen on Spotify"} className={"podcast spotify-podcast-url"}>Listen on Spotify</a>
                                  <a href={"https://www.buzzsprout.com/1895628"} target="_blank" title={"Listen on Buzzsprout"} className={"podcast buzzsprout-podcast-url"}>Listen on Buzzsprout</a>
                              </div>
                          </div>
                      </div>
                  </section>

                  <section className="sub-content-block podcast-episodes">
                      <div className="sub-content-block__item sub-content-block__item--text">
                          <div>
                              <h2><b>Episodes</b></h2>

                              { allPrismicPodcastEpisode.nodes.map((episode, idx) => (
                                <div className={"podcast-episode"} key={`podcast-episode-${ idx }`}>
                                    <Link to={episode.url} className={"podcast-episode--play"}>
                                  <span className={"podcast-episode--icon"}>
                                      <i />
                                  </span>
                                    </Link>
                                    <Link to={episode.url} className={"podcast-episode--link"}>
                                  <span className={"podcast-episode--title"}>
                                      <strong>{episode.data.title.text}</strong>
                                      <small>{episode.data['published_on']}</small>
                                  </span>
                                        <span className={"podcast-episode--cover"}>
                                      <img src={episode.data.cover.url} alt={episode.data.cover.all} />
                                  </span>
                                    </Link>
                                </div>
                              )) }
                          </div>
                      </div>
                  </section>
              </Layout>
          </ColorThemeContextProvider>
      </>
    );
}

export const query = graphql`
query PodcastEpisodesQuery($lang: String) {
  allPrismicPodcastEpisode(filter: {lang: {eq: $lang}}, sort: {order: DESC, fields: data___published_on}) {
    nodes {
      _previewable
      data {
        published_on(formatString: "MMMM DD, YYYY")
        title {
          text
        }
        cover {
          alt
          url
        }
      }
      url
    }
  }
  prismicPodcast(lang: {eq: $lang}, type: { eq: "podcast" }) {
      _previewable
      data {
        header_image {
        url
        alt
      }
    }   
  }
  
  prismicHome(lang: {eq: $lang}) {
    data {
      menu_links {
          menu_link {
              url
              type
          }
          name {
            text
          }
      }
      footer_links {
        footer_link {
         url
         type
        }
        name {
          text
        }
      }
    }
  }
  
  allPrismicBlogPost(filter: {lang: {eq: $lang}}) {
    nodes {
      url
      data {
        title {
            text
        }
      }
    }
  }
}
`

export default Podcast;
